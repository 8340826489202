.slideshow-container {
    position: relative;
    width: 100%;
    height: 50vh;
    overflow: hidden;
  }
  
  .image-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .image-slide.active {
    opacity: 1;
    z-index: 2; /* Make sure active slide is above others */
    animation: slideIn 1s ease-in-out forwards;
  }
  
  .image-slide.outgoing {
    opacity: 0;
    z-index: 1; /* Ensure outgoing slide is below the active one but above the inactive ones */
    animation: slideOut 1s ease-in-out forwards;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
  /* Card container */
.card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin: 20px auto;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 300px; /* Adjust based on your preference */
  }
  
  /* Image styling */
  .card-img {
    width: 100%;
    display: block; /* Removes bottom space/gap */
    height: auto;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  /* Caption styling */
  .card-caption {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 15px;
    font-size: 14px;
    line-height: 1.5;
    background-color: #f9f9f9; /* Light background for the caption */
  }
  
  /* Hover effect */
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  