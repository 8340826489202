.news-card .news-image, .news-card .news-video {
    width: 100%; /* Makes sure the image or video fits the card width */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Cover the box, might crop the image */
}

.news-card {
    overflow: hidden; /* Ensures nothing goes outside the card */
}

.news-text {
    padding: 15px; /* Adds padding around the text */
}
.mainTitle {
    font-size: 32px;
    text-align: left;
    background-color: #6ac9cb; /* Background color */
    color: #fff; /* Text color */
    width: 100%;
    padding: 20px 10px 20px;
    margin:  0px 0 20px 0; /* Adjust margin for spacing below the title */
    box-sizing: border-box;
    border-bottom: 0.3rem solid #71716F;
  }