/* Navbar overall styling */
.navbar {
    background-color: #E6E5E5; /* Deep teal background */
    height: 8vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .navbar-container {
    width: 100%; /* Ensure full width */
    display: flex;
    justify-content: space-between; /* This should already align items correctly */
    align-items: center;
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #E6E5E5;
    z-index: 1000;
}

  /* Logo styling */
  .navbar-logo img {
    height: 7vh; /* Adjust based on your logo */
    width: auto;
  }
  
  /* Navigation menu */
  .nav-menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    margin-right: 1rem;
  }
  
  /* Navigation items */
  .nav-item {
    margin-left: 20px;
  }
  
  /* Link styling */
  .nav-links {
    color: #000000; /* White color for the links */
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
  }
  
  /* Hover effect for links */
  .nav-links:hover {
    background-color: #6AC9CB; /* Lighter teal background on hover */
    color: #ffffff;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    /* .nav-menu {
      flex-direction: column;
      align-items: center;
      width: 100%;
    } */
  
    .nav-item {
      margin: 10px 0;
    }
  }
  /* Hamburger menu styling */
.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 5px;
  transition: all 0.3s ease-in-out;
}

/* Side menu (hidden by default) */
.nav-menu.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 8vh; /* Height of the navbar */
  right: 0;
  width: 50%; /* Adjust based on your preference */
  height: 92vh; /* Adjust based on the navbar height */
  background-color: #E6E5E5;
  transition: all 0.3s ease;
  transform: translateX(0%);
  z-index: 1000;
}

/* Responsive styling */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-menu {
    display: none;
  }

  .nav-menu.active {
    display: flex;
  } .nav-menu {
    margin-right: 0rem;
  }
  .language-selector .dropdown-toggle {
    cursor: pointer;
    background-color: #E6E5E5; /* Adjust the background color as needed */
    border: 1px solid #E6E5E5; /* Optional: adds a border to the dropdown button */
    font-size: 16px;
    border-radius: 5px; /* Optional: rounds the corners of the dropdown button */
   
    
  }
}
.language-selector .dropdown-toggle {
  cursor: pointer;
  background-color: #E6E5E5; /* Adjust the background color as needed */
  border: 1px solid #E6E5E5; /* Optional: adds a border to the dropdown button */
  font-size: 16px;
  border-radius: 5px; /* Optional: rounds the corners of the dropdown button */
  margin-top:-8px;
}
.dropdown-menu1 {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px; /* Adjust this value as needed */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
  right: 20px;
  padding: 5px 0; /* Adds padding inside the dropdown to not touch the borders */
}

.dropdown-menu1 li {
  padding: 8px 20px; /* Adjust padding as needed for each item */
  cursor: pointer;
  list-style-type: none;
  display: flex; /* Makes the flag and text align nicely */
  align-items: center; /* Aligns items vertically in the center */
}

.dropdown-menu1 li:hover {
  background-color: #f1f1f1;
}

.flag-icon {
  width: 20px; /* Sets a fixed width for flag images */
  height: 15px; /* Sets a fixed height for flag images */
  margin-right: 10px; /* Adjust spacing between flag and text */
  object-fit: cover; /* Ensures the image covers the area nicely */
}
