.about-us-container {
    margin: 20px 0;
    padding: 0 0 20px ;
    text-align: center;
    width: 100%;
}
p{
    font-size: 1.15rem;
    padding: 0.23rem;
}
.about-us-container h1 {
    text-align: center;
    margin-bottom: 20px;
    margin-top: -20px;
}

.about-us-container p {
    text-align: center;
    line-height: 1.6;
    margin-bottom: 15px; /* Adds spacing between paragraphs */
}
#firstp{
    margin-top: 0.1rem;
}
.mainTitle {
    font-size: 32px;
    text-align: left;
    background-color: #6ac9cb; /* Background color */
    color: #fff; /* Text color */
    width: 100%;
    padding: 20px 10px 20px;
    margin:  -20px 0 20px 0; /* Adjust margin for spacing below the title */
    box-sizing: border-box;
    border-bottom: 0.3rem solid #71716F;
    
  }
  @media (max-width: 768px) {
    .mainTitle {
      
    text-align: center;
    padding: 20px 0;
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* This will align items at the start of the container */
    flex-wrap: wrap; /* Allows items to wrap in smaller screens */
}

.text-container, .image-container {
    flex: 1;
    min-width: 0; /* Prevents flex items from overflowing their container */
}
.text-container{
    padding: 1rem;
    
}
.image-container {
    max-width: 50%; /* Limits the image container to 50% of its parent container's width */
}

.image-container img {
    max-width: 95%;
    max-height: 400px; /* Adjust this value based on your needs */
    height: auto;
    object-fit: cover; /* Adjusts the object fit to cover to maintain aspect ratio */
    width: auto; /* Ensures the width is auto to maintain aspect ratio */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
    }

    .text-container, .image-container {
        max-width: 100%; /* Allows the text and image to take full width on small screens */
    }

    .image-container {
        order: 2; /* Ensures the image comes after the text if you want to change the order */
    }
    .text-container{
        margin-left: 0rem;
        padding: 0.6rem;
    }
}
