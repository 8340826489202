.mainTitle {
    font-size: 32px;
    text-align: left;
    background-color: #6ac9cb; /* Background color */
    color: #fff; /* Text color */
    width: 100%;
    padding: 20px 10px 20px;
    margin:  -20px 0 20px 0; /* Adjust margin for spacing below the title */
    box-sizing: border-box;
    border-bottom: 0.3rem solid #71716F;
    margin-top: -20px
  }
  @media (max-width: 768px) {
    .mainTitle {
      
    text-align: center;
    padding: 20px 0;
    }
  }